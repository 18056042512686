.SquareButton {
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 2.75rem;
  width: 2.75rem;
  cursor: pointer;
}

.SquareButton-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: 3.5rem 3.5rem, 2.5rem 2.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

.SquareButton-content--disabled {
  filter: grayscale();
}

.SquareButton-aura {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  background-size: 3rem 3rem;
  background-position: center;
  background-repeat: no-repeat;
  visibility: hidden;
}

.SquareButton:hover .SquareButton-aura {
  visibility: visible;
}

.SquareButton-outline {
  --outline-size: 2.625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.625rem;
  width: 2.625rem;
  background-position-y: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.SquareButton-outline--grey {
  background-position-x: 0;
}

.SquareButton-outline--gold {
  background-position-x: calc(-1 * var(--outline-size));
}

.SquareButton-outline--green {
  background-position-x: calc(-2 * var(--outline-size));
}
