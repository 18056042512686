.KlassList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}

.KlassList-item {
  margin: var(--sp-md);
  margin-top: 6px;
  margin-bottom: 0px;
}
